var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("textarea", {
    staticClass: "govuk-textarea",
    attrs: {
      id: _vm.id,
      name: _vm.name,
      rows: _vm.rows,
      "aria-describedby": _vm.ariaDescribedBy
    },
    domProps: { value: _vm.value },
    on: {
      keyup: function($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        ) {
          return null
        }
        return _vm.onEnter($event)
      },
      input: function($event) {
        return _vm.$emit("input", $event.target.value)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }